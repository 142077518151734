import React, { Component } from 'react';
import './InputBox.scss';

// You can add links to the labelText by using this format: [This is the link text|http://my.url.com]
// Links will always open in a new window.
//
// linkedStateProp is the name of the property in the parent state that you wish to be
// updated when this component changes. An event will be broadcasted during validation to update that prop.

class InputBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasError: true,
            showErrorLabel: false,
            errorMessage: ''
        }
    }

    componentDidMount() {
        
        this.isPassword = this.props.isPassword;
    }

    render() {
        return <div className="inputBox">
            <label className={this.state.showErrorLabel ? 'errorLabel' : ''} htmlFor={this.props.id + "_inputField"}>
                {this.getLabelText()}
            </label>
            <input className={this.state.showErrorLabel ? 'error' : ''} id={this.props.id + "_inputField"} 
                maxLength={this.props.maxLength} onChange={event => this.onChangeHandler(event)} onBlur={event => this.onBlur(event)} 
                type={this.props.isPassword ? "password" : "text"} placeholder={this.props.placeholder} ></input>
        </div>
    }

    getLabelText = () => {
        if (this.state.showErrorLabel && this.state.errorMessage != '') return this.state.errorMessage;
        if (this.state.showErrorLabel) return this.props.errorText;
        return this.props.labelText;
    }

    onChangeHandler = (e) => {
        
        // this is fired every letter as you type

        var err = this.doValidation(e);

        // if we are already showing an error, clear that the instant we are in a valid state
        if (this.state.showErrorLabel && !err)
        {
            this.setState({
                showErrorLabel: false,
                hasError: false
            });
        }

        if (this.props.onChangeHandler) {
            this.props.onChangeHandler();
        }

    }

    onBlur = (e) => {

        // this is only fired when you leave the input box
        this.doValidation(e);
        this.setState({showErrorLabel: this.state.hasError || this.props.error});

        if (this.props.onBlur) {
            this.props.onBlur(this.state.hasError);
        }
    }

    doValidation = (e) => {

        var err = false;
        var val = e.target.value;

        if (this.props.validate && this.props.validate === 'empty') {
            if (val === '') err = true;
        } 

        else if (this.props.validate && this.props.validate === 'email') {
            if (val === '' || 
            val.indexOf('@') === -1 || 
            val.indexOf('.') === -1 ){
                //invalid email
                err = true;
            } else {
                err = false;
            }
        }

        else if (this.props.validate && this.props.validate === 'matchPassword') {
            if (val === '') {
                err = true;
            }
        }

        else if (this.props.validate && this.props.validate === 'createPassword') {

            // password rules:
            // 8-64 characters
            // No whitespace
            // Recommended to not have limits on which symbols are allowed (whitespace is considered part of the symbol class)
            // Cannot match username

            if (val.length < 8 || val.length > 64) err = true;
            if (val.indexOf(' ') !== -1) err = true;
        }

        else if (this.props.validate && this.props.validate === 'initials') {

            // check for inappropriate initials
            var badlist = ['ass', 'fuk', 'fuc', 'fck', 'fkk', 'fku', 'fcu', 'dik', 'dic'];
            if (val.length < 3) {
                this.setState({errorMessage: 'Leaderboard Initials: Use 3 letters'});
                err = true;
            } 
            
            else if (badlist.includes(val.toLowerCase())) {
                err = true;
                this.setState({errorMessage: 'Leaderboard Initials: Inappropriate'});
            } 

            else {

                // check for non-alpha characters
                var patt = /^[a-zA-Z]+$/;
                var allowed = (val.toLowerCase().match(patt) == val.toLowerCase());

                if (!allowed) {
                    err = true;
                    this.setState({errorMessage: 'Leaderboard Initials: Invalid Characters'});
                }
            }

        }

        this.setState({hasError: err});

        if (!err) {
            this.setState({errorMessage: ''});
        }

        if (this.props.onChange) {
            // we will pass an event with a "value" prop on it to a onChange handler specified in the props
            // of this component, and set the parent state linked prop if specified
            var passedEvent = new Event('inputboxevent');
            passedEvent.value = e.target.value;
            passedEvent.error = err;
            passedEvent.linkedStateProp = this.props.linkedStateProp;
            passedEvent.id = this.props.id;
            this.props.onChange(passedEvent);
        }

        return err;
    }

}

export default InputBox;