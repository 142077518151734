function showSeriesNav() {
  const navEleID = 'block-ten-seriesnav';
  const navEle = document.getElementById(navEleID);

  if (navEle === null) {
    console.warn('Navigation menu element was not found. Class ID -', navEleID);
    return;
  }

  navEle.style.cssText += 'display: block';
}

function hidePageNav () {
  const header = document.querySelector("header");

  if (header === null) {
    console.warn("No Header found");
    return;
  }

  header.style.display = "none";
}

export { showSeriesNav, hidePageNav }