import React, { Component } from 'react';
import './LogoutSuccess.scss';

class LogoutSuccess extends Component {

    componentDidMount() {}

    render(props) {
        return <div className={this.props.active ? 'ui logoutSuccess active' : 'ui logoutSuccess'}>
            <div className="ui-content">
                <h2>LOGGED OUT</h2>
                <p>You have been logged out.</p>
                <button id="successCloseButton" className="button" onClick={this.props.closeHandler}>CLOSE</button>
            </div>
        </div>
    }
}

export default LogoutSuccess;
