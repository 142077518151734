import React, { Component } from 'react';
import './NewPassword.scss';

import InputBox from '../InputBox/InputBox';
import DaltonAPI from '../../Services/DaltonAPI';
import Tracker from "../../utils/Tracker";
import SFX from '../../utils/SFX';
import closeButton from '../../assets/images/close.png';

// This is STEP THREE (the final step) in forgot password: enter your new password twice and submit.
// This is also used by logged in users to change their password, and they will not have to log in again.

class NewPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            newPasswordError: true,
            confirmPasswordError: true,
            matchError: true,
            codeSent: false,
            authToken: ''
        };
    }

    componentDidMount() {}

    render(props) {

        return <div className={this.props.active ? 'ui pwChangeView active' : 'ui pwChangeView'}>
            <div className="ui-content">
                <div className="closeButton" onClick={this.props.closeHandler}><img src={closeButton}/></div>

                <h2>UPDATE PASSWORD</h2>
                <p>You must now change your password to continue.</p>

                <InputBox id="newPassword" labelText="New Password" placeholder="password (8 or more characters)" isPassword="true" onChange={this.onChangeHandler} onBlur={this.handleBlur}
                    validate="createPassword" errorText="New Password: (8 or more characters)"/>
                <InputBox id="confirmPassword" labelText="Confirm Password" error={!this.checkForMatch()} placeholder="Enter your password again" isPassword="true" onChange={this.onChangeHandler} onBlur={this.handleBlur}
                    validate="matchPassword" errorText="Confirm Password: Passwords must match"/>

                <p className="statusBlock">
                    <span className="statusText"></span>
                </p>

                <div className="buttonBar">
                    <button id="submitPWCode" className="button submitButton" disabled={this.checkForErrors()} onClick={this.handleChangeSubmit}>SUBMIT</button>
                </div>

                <div>
                    <p className="error">{this.state.errorMessage}</p>
                </div>
            </div>
        </div>

    }

    checkForErrors = () => {
        if (!document.getElementById('newPassword_inputField')) return true; // we're not ready
        return (this.state.newPasswordError || this.state.confirmPasswordError || this.state.matchError);
    }

    checkForMatch = () => {
        if (!document.getElementById('newPassword_inputField')) return true;
        return (document.getElementById('newPassword_inputField').value === document.getElementById('confirmPassword_inputField').value); // text fields match
    }

    onChangeHandler = (e) => {

        if (e != null) {

            console.log('>> error: ' , e.error);

            var newState = {};

            if (e.linkedStateProp != null) {
                newState[e.linkedStateProp] = e.value;
            }

            if (e.error != null) {
                if (e.id === 'newPassword') newState.newPasswordError = e.error;
                if (e.id === 'confirmPassword') newState.confirmPasswordError = e.error;
                newState.matchError = !this.checkForMatch();
            }

            this.setState(newState);
        }

        return;
        
    }

    handleBlur = (err) => {

        if (!this.checkForMatch()) {
            err = true;
        }

        // errors labels are shown only on blur, so use this to fire analytics tag
        if (err) {
            if (!this.state.hasError) Tracker.trackPageView('new password', 'error');
        }

        this.setState({hasError: err});
    }

    handleChangeSubmit = (event) => {

        event.preventDefault();

        SFX.playClick();

        if (this.state.hasError) return;

        DaltonAPI.changePassword(document.getElementById('newPassword_inputField').value, DaltonAPI.authToken)
            .then(data => {
                if (data.errors && data.errors.length > 0)
                {
                    var err = data.text().then(errorResponse => {
                        var errorObj = JSON.parse(errorResponse);
                        
                        this.setState(
                            {
                                errorMessage: 'There was an error.',
                                hasError: true
                            }
                        );

                        Tracker.trackPageView('new password', 'error');

                    });
                    return err;
                } else {
                    // SUCCESS!
                    DaltonAPI.authToken = data;
                    this.props.pwChangeSuccessHandler();
                    return true;
                }
                
            }).catch(error => {
                console.log(error);
                return false;
            });
    }
}

export default NewPassword;