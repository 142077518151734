class SFX {

    static button_click_url = process.env.PUBLIC_URL + '/sounds/button_click.mp3';
    static audio;

    static playClick = () => {
        if (!SFX.audio) {
            SFX.audio = new Audio(SFX.button_click_url);
        }
        SFX.audio.play();
    }
}

export default SFX;