class Tracker {

    static bindTracking;

    static trackPageView(screen, section) {

        console.log('** trackPageView: ' + screen + ',' + section);

        Tracker.bindTracking(
            {
                type: 'dynamic-page',
                data: {
                    pageviewEvent: 1,
                    micrositeName: "the cube",
                    screenName: screen,
                    screenSection: section
                }
            }
        );
    }

    static trackPageViewSponsor(screen, section, sponsorPresent, sponsorName) {

        console.log('** trackPageViewSponsor: ' + screen + ',' + section + ',' + sponsorPresent + ',' + sponsorName);

        Tracker.bindTracking(
            {
                type: 'dynamic-page',
                data: {
                    pageviewEvent: 1,
                    micrositeName: "the cube",
                    screenName: screen,
                    screenSection: section,
                    sponsorPresent: sponsorPresent,
                    sponsorName: sponsorName
                }
            }
        );
    }

    static trackInteraction(name, location) {

        console.log('** trackInteraction: ' + name + ',' + location);
        
        Tracker.bindTracking(
            {
                type: 'user-interaction',
                data: {
                    micrositeName: "the cube",
                    interactionEvent: 1,
                    interactionName: name,
                    interactionLocation: location
                }
            }
        );
    }

    static trackInteractionSponsor(name, location, sponsorPresent, sponsorName) {

        console.log('** trackInteractionSponsor: ' + name + ',' + location + ',' + sponsorPresent + ',' + sponsorName);
        
        Tracker.bindTracking(
            {
                type: 'user-interaction',
                data: {
                    micrositeName: "the cube",
                    interactionEvent: 1,
                    interactionName: name,
                    interactionLocation: location,
                    sponsorPresent: sponsorPresent,
                    sponsorName: sponsorName
                }
            }
        );
    }

    static trackGameEvent(type, mode, title, score, outcome) {

        console.log('** trackGameEvent: ' + type + ',' + mode + ',' + title + ',' + score + ',' + outcome);
        
        var obj = {
            type: 'user-interaction',
            data: {
                micrositeName: "the cube",
                gameInteractionEvent: 1,
                gameInteractionTitle: type,
                gameMode: mode,
                gameTitle: title
            }
        }

        if (score !== undefined) {
            obj.data.value = score;
        }

        if (outcome !== undefined) {
            obj.data.outcome = outcome;
        }

        Tracker.bindTracking(obj);
    }
}

export default Tracker;