import React, { Component } from 'react';
import './Loader.scss';

class Loader extends Component {

    componentDidMount() {}

    render(props) {

        console.log(this.props.progression);

        return <div className={!this.props.isLoaded ? "loader" : "loader hidden"}>
            Loading . . .
            <div className="progressContainer">
            <div className="progressBar" style={{width: this.props.progression * 100 + '%'}}></div>
            </div>
        </div>
        
    }
}

export default Loader;