import React, { Component } from 'react';
import './Login.scss';
import UserData from "../../Models/UserData";
import InputBox from '../InputBox/InputBox';
import DaltonAPI from '../../Services/DaltonAPI';
import Tracker from "../../utils/Tracker";
import SFX from '../../utils/SFX';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: 'cube001@test.com',
            hasError: true,
            emailError: true,
            passwordError: true
        };
    }

    componentDidMount() {

    }

    render(props) {

        return <div className={this.props.active ? 'login active' : 'login'}>
            <InputBox id="loginEmail" labelText="Email" linkedStateProp="email" onChange={this.handleInputBoxChange} onBlur={this.handleBlur} placeholder="email address" validate="email" 
                errorText="Email: Check your email and password"/>
            <InputBox id="loginPassword" labelText="Password" placeholder="password" isPassword="true" onChange={this.handleInputBoxChange} onBlur={this.handleBlur} 
                validate="createPassword" errorText = "Password: Check your email and password"/>

            <button id="submitLogin" className="button submitButton" disabled={this.checkForErrors()} onClick={this.handleSubmit}>LOG IN</button>

            <div className="forgotPassword" onClick={this.handleForgotPasswordClick}>Forgot your password?</div>
            <div>
                <p className="error">{this.state.errorMessage}</p>
            </div>

        </div>
    }

    checkForErrors = () => {
        return this.state.emailError || this.state.passwordError;
    }

    handleInputBoxChange = (e) => {

        if (e != null) {

            var newState = {};

            if (e.linkedStateProp != null) {
                newState[e.linkedStateProp] = e.value;
            }

            if (e.error !== null) {
                if (e.id === 'loginEmail') newState.emailError = e.error;
                if (e.id === 'loginPassword') newState.passwordError = e.error;
            }

            this.setState(newState);
        }
    }

    handleBlur = (err) => {

        // errors labels are shown only on blur, so use this to fire analytics tag

        if (err) {
            if (!this.state.hasError) Tracker.trackPageView('login', 'error');
        }

        this.setState({hasError: err});
    }

    handleForgotPasswordClick = (e) => {
        if (this.props.forgotPasswordHandler) {
            this.props.forgotPasswordHandler();
        }
    }


    handleSubmit = (event) => {
        const { weekName } = this.props;
        event.preventDefault();

        SFX.playClick();

        var eml = this.state.email;
        var authToken = '';
        
        DaltonAPI.login(eml, document.getElementById('loginPassword_inputField').value)
            .then(data => {
                if (data.errors && data.errors.length > 0) {                    
                    this.setState({
                        emailError: true,
                        passwordError: true,
                        errorMessage: 'There was an error. Please check your username and password and try again.'
                    });

                    Tracker.trackPageView('login', 'error');

                    return false;

                } else {
                    // login success: data is token

                    this.setState({
                        hasError: false,
                        errorMessage: ''
                    });

                    DaltonAPI.authToken = data;
                    DaltonAPI.isLoggedIn = true;

                    var userData = new UserData();
                    userData.authToken = data;
                    userData.email = this.state.email;
                    userData.userID = data.split('|')[0];
                    

                    DaltonAPI.getUser(data)
                        .then(profileData => {

                            var profileJSON = JSON.parse(profileData);
                            // console.log(profileJSON);
                            if (profileJSON.errors && profileJSON.errors.length > 0) 
                            {
                                console.log('>> user profile error');
                                this.setState({
                                    hasError: true,
                                    errorMessage: 'User profile error.'
                                });

                                Tracker.trackPageView('login', 'error');

                            } else {

                                this.setState({
                                    hasError: false,
                                    errorMessage: ''
                                });

                                var attr;
                                if (profileJSON.userProfileResponses.cube && profileJSON.userProfileResponses.cube.attributes) 
                                {
                                    attr = profileJSON.userProfileResponses.cube.attributes;
                                    
                                    userData.challengePlayCount =       attr[`${weekName}_challengePlayCount`];
                                    userData.entryCount =               attr[`${weekName}_entryCount`];

                                    userData.initials = attr.initials;
                                }
                            }

                            // console.log(userData);

                            this.props.loginSuccessHandler(userData);
                        });

                    return DaltonAPI.authToken;
                }
            }).catch(error => {
                console.log(error);
                return false;
            });
    }

}

export default Login;