import React, { Component } from 'react';
import './LoginSuccess.scss';

class LoginSuccess extends Component {

    componentDidMount() {}

    render(props) {
        return <div className={this.props.active ? 'ui loginSuccess active' : 'ui loginSuccess'}>
            <div className="ui-content">
                <h2>SUCCESS</h2>
                <p>You are now logged in!</p>
                <button id="successCloseButton" className="button" onClick={this.props.closeHandler}>CLOSE</button>
            </div>
        </div>
    }
}

export default LoginSuccess;
