import React, { Component } from 'react';
import './ForgotPassword.scss';

import InputBox from '../InputBox/InputBox';
import DaltonAPI from '../../Services/DaltonAPI';
import Tracker from "../../utils/Tracker";
import SFX from '../../utils/SFX';
import closeButton from '../../assets/images/close.png';

// This is STEP ONE in forgot password: enter your email, click submit and get an email with your reset code.
class ForgotPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            hasError: true,
            passwordResetSent: false
        };
    }

    componentDidMount() {}

    render(props) {

        return <div className={this.props.active ? 'ui forgotPassword active' : 'ui forgotPassword'}>
            <div className="closeButton" onClick={this.props.closeHandler}><img src={closeButton}/></div>
            <div className="ui-content">
                <div className="wrapper">
                    <h2>FORGOT PASSWORD</h2>
                    <p>Enter your account email to receive a reset password link.</p>

                    <InputBox id="resetPasswordEmail" labelText="Email" linkedStateProp="email" onChange={this.handleInputBoxChange} onBlur={this.handleBlur} placeholder="email address" validate="email" 
                        errorText="Email: you must enter a valid email address"/>

                    <p className="statusBlock">
                        <span className="statusText highlight">{this.state.passwordResetSent ? 'A password reset link has been sent!' : ''}</span>
                        <span className="statusText">{this.state.passwordResetSent ? ' If this email is associated with an account you will receive a password reset code in your inbox.' : ''}</span>
                    </p>

                    <div className="buttonBar">
                        <button id="backToLogin" className="button backButton" onClick={this.backButtonClick}>GO BACK</button>
                        
                        {!this.state.passwordResetSent && 
                            <button id="submitPasswordReset" className="button submitButton" disabled={this.state.hasError} onClick={this.handleSubmit}>SEND RESET EMAIL</button>
                        }

                        {this.state.passwordResetSent && 
                            <button id="pwResetContinue" className="button" onClick={this.iHaveCodeClick}>I HAVE A RESET CODE</button>
                        }
                    </div>

                    <div>
                        <p className="error">{this.state.errorMessage}</p>
                    </div>
                </div>
            </div>

        </div>

    }

    resetState = () => {
        this.setState({
            email: '',
            hasError: true,
            passwordResetSent: false
        });
    }

    backButtonClick = () => {
        SFX.playClick();
        this.resetState();
        this.props.backButtonHandler();
    }

    iHaveCodeClick = () => {
        SFX.playClick();
        this.resetState();
        this.props.showResetCodeInput();
    }

    handleInputBoxChange = (e) => {

        // if we linked a state prop to a child InputBox
        if (e != null && e.linkedStateProp != null) {
            this.setState({
                [e.linkedStateProp]: e.value,
                hasError: e.error
            });
        }
    }

    handleBlur = (err) => {

        // errors labels are shown only on blur, so use this to fire analytics tag
        if (err) {
            if (!this.state.hasError) Tracker.trackPageView('login', 'forgot password - error');
        }

        this.setState({hasError: err});
    }

    handleSubmit = (event) => {

        event.preventDefault();

        SFX.playClick();

        if (this.state.hasError) return;

        var eml = this.state.email;
        var authToken = '';

        DaltonAPI.resetPassword(eml)
            .then(data => {

                if (data.errors && data.errors.length > 0) {

                    console.log('>> pw reset error');

                    Tracker.trackPageView('login', 'forgot password - error');

                    var err = data.text().then(errorResponse => {
                        var errorObj = JSON.parse(errorResponse);
                        return errorObj;
                    });
                } else {
                    // success, but we get no info
                    this.setState({passwordResetSent: true});

                    Tracker.trackPageView('login', 'forgot password – success');
                }
                return true;
                
            }).catch(error => {
                console.log(error);
                return false;
            });
    }
}

export default ForgotPassword;