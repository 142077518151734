import React, { Component } from 'react';
import './ResetPWCode.scss';

import InputBox from '../InputBox/InputBox';
import DaltonAPI from '../../Services/DaltonAPI';
import Tracker from "../../utils/Tracker";
import SFX from '../../utils/SFX';
import closeButton from '../../assets/images/close.png';

// This is STEP TWO in forgot password: enter your reset code, click submit and if accepted 
// move on to changing your password.
class ResetPWCode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            code: '',
            hasError: true,
            codeSent: false,
            pwState: 0
        };
    }

    componentDidMount() {}

    render(props) {

        return <div className={this.props.active ? 'ui pwResetView active' : 'ui pwResetView'}>
            <div className="ui-content extra-padding">
                <div className="closeButton" onClick={this.props.closeHandler}><img src={closeButton}/></div>

                <h2>PASSWORD RESET CODE</h2>
                <p>Enter the password reset code that was sent to you via email.</p>

                <InputBox id="passwordResetCode" labelText="Password Reset Code" linkedStateProp="code" onChange={this.handleInputBoxChange} onBlur={this.handleBlur} placeholder="reset code" validate="empty" 
                    errorText="Password Reset Code: You must enter your code"/>

                <p className="statusBlock">
                    <span className="statusText"></span>
                </p>

                <div className="buttonBar">
                    <button id="backToPWReset" className="button backButton" onClick={this.props.backButtonHandler}>GO BACK</button>
                    <button id="submitPWCode" className="button submitButton" disabled={this.state.hasError} onClick={this.handleCodeSubmit}>VERIFY ME</button>
                </div>

                <div>
                    <p className="error">{this.state.errorMessage}</p>
                </div>
            </div>
        </div>
    }

    handleInputBoxChange = (e) => {
        
        // if we linked a state prop to a child InputBox
        if (e !== null && e.linkedStateProp !== null) {
            this.setState({
                [e.linkedStateProp]: e.value,
                hasError: e.error
            });
        }
    }

    handleBlur = (err) => {

        // errors labels are shown only on blur, so use this to fire analytics tag
        if (err) {
            if (!this.state.hasError) Tracker.trackPageView('login', 'password reset code - error');
        }

        this.setState({hasError: err});
    }

    handleCodeSubmit = (event) => {

        event.preventDefault();

        SFX.playClick();

        if (this.state.hasError) return;

        var authToken = '';

        DaltonAPI.resetPWCode(this.state.code)
            .then(response => {

                if (response.errors && response.errors.length > 0) {
                    // error

                    console.log('>> password code error');
                    this.setState({
                        hasError: true,
                        errorMessage: 'There was a problem processing your reset code.'
                    });

                    Tracker.trackPageView('login', 'password reset code - error');

                    // var err = response.text().then(errorResponse => {
                    //     var errorObj = JSON.parse(errorResponse);
                    //     return errorObj;
                    // });

                    return false;

                } else {
                    // success

                    // store the auth token for later
                    DaltonAPI.authToken = response;

                    Tracker.trackPageView('login', 'password reset code - success');

                    // reset view for next time
                    this.setState({
                        code: '',
                        hasError: true,
                        codeSent: false,
                        pwState: 0
                    });
                    this.props.successHandler();

                    return true;
                }

                
            }).catch(error => {
                console.log(error);
                return false;
            });
    }
}

export default ResetPWCode;