import React, { Component } from 'react';
import './AccountTabs.scss';
import Login from "../Login/Login";
import CreateAccount from "../CreateAccount/CreateAccount";
import withAnalyticsWrapper from '../AnalyticsWrapper/AnalyticsWrapper';
import UserData from "../../Models/UserData";
import Tracker from '../../utils/Tracker';
import SFX from '../../utils/SFX';
import closeButton from '../../assets/images/close.png';

class AccountTabs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tabIndex: 0
        }
    }

    componentDidMount() {
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.active && prevProps.active !== this.props.active) {
            if (this.state.tabIndex === 0) {
                Tracker.trackPageView('create account', 'lander');
            } else {
                Tracker.trackPageView('login', 'lander');
            }
        }
    }

    render(props) {
        return <div className={this.props.active ? 'ui accountTabs active' : 'ui accountTabs'}>
            <div className="closeButton" onClick={this.props.closeHandler}><img src={closeButton}/></div>
            <div className="ui-content">
                <div className="tabRow">
                    <h2 className={this.state.tabIndex === 0 ? "tabName active" : "tabName"} onClick={this.accountClickHandler}>CREATE&nbsp;ACCOUNT</h2>
                    <h2 className={this.state.tabIndex === 1 ? "tabName active" : "tabName"}  onClick={this.loginClickHandler}>LOGIN</h2>
                </div>

                <div className={this.state.tabIndex === 0 ? "tab active" : "tab"}>
                    <CreateAccount successHandler={this.props.createAccountSuccessHandler } weekName={this.props.weekName}/>
                </div>
                <div className={this.state.tabIndex === 1 ? "tab active" : "tab"}>
                    <Login loginSuccessHandler={this.props.loginSuccessHandler} forgotPasswordHandler={this.props.forgotPasswordHandler} weekName={this.props.weekName} />
                </div>
            </div>
        </div>
    }

    accountClickHandler = () => {
        SFX.playClick();
        this.setState({tabIndex: 0});

        Tracker.trackPageView('create account','lander');
    }

    loginClickHandler = () => {
        SFX.playClick();
        this.setState({tabIndex: 1});

        Tracker.trackPageView('login','lander');
    }

}

export default AccountTabs;