import React, { Component } from 'react';

function withAnalyticsWrapper(WrappedComponent) {
  return class withAnalyticsWrapper extends Component {
    constructor(props) {
      super(props);
      this.dev = this.determineDevMode(props);
      this.pollTime = 1000;
      this.pollNum = 4;
      this.defaultObj = {
        type: 'dynamic-page',
        data:{
          'series_name' : 'The Cube',
          'section' : ['show info', ''],
          'device' : 'web',
          'device_type' : 'web',
          'network' : 'tbs'
        }
      };
      this.state = {
        objArray: new Set()
      }
      this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      this.fireCall(this.state.objArray);
    }

    init = () => {
      if (this.checkWindow()) {
        this.trackMetrics = window.trackMetrics;
      } else {
        console.warn('Window object was not found. Waiting...')
        this.initPolling(0);
      }
    }

    determineDevMode = (props) => {
      let devModeFlag = (props.dev === 'true') ? true : false;

      return process.env.NODE_ENV !== 'production' && devModeFlag;
    }

    initPolling = (num) => {
      window.setTimeout(() => {
        if (this.checkWindow()) {
          this.trackMetrics = window.trackMetrics;
          this.fireCall(this.state.objArray);
        } else {
          if (num > this.pollNum) {
            console.error('Window object was still not found. Ending polling.');
            this.includeDevTrackMetrics();
            return;
          } else {
            console.warn('Window object was still not found. Retrying...')
            num++;
            this.initPolling(num);
          }
        }
      }, this.pollTime)
    }

    bindTracking = (payload) => {

      console.log('>> bindTracking' + payload);
      
      this.storeCall(payload);
    }

    checkWindow = () => {
      return ((window.trackMetrics) && (typeof window.trackMetrics === 'function'))
    }

    includeDevTrackMetrics = () => {
      if (this.dev) {
        console.log('Dev mode detected. Including dev tracking function...');
        (typeof window.trackMetrics === 'undefined') ? (this.trackMetrics = () => {}) : console.warn('Tracking function already present. Aborting adding dev function...');
        this.fireCall(this.state.objArray);
      }
    }

    fireCall = (objArray) => {
      if ((typeof this.trackMetrics === 'undefined') || (this.state.objArray.size === 0)) {
        return;
      }

      for (const obj of objArray) {
        try {
          let finalObj = this.formData(obj);
          if (this.dev)
            console.log('trackMetrics call with', finalObj);
          this.trackMetrics(finalObj);
          objArray.delete(obj);
        } catch(e) {
          console.error('trackMetrics failed with error', e);
        }
      }

      this.setState({
        objArray: objArray
      })
    }

    formData = (payload) => {
      const data = {
        type: (payload.type) ? payload.type : this.defaultObj.type,
        data: {...this.defaultObj.data, ...payload.data}
      }

      return data;
    }

    storeCall = (obj) => {
      const objArray = this.state.objArray;

      objArray.add(obj);

      this.setState({
        objArray: objArray
      })
    }

    render = () => {
      return (
       <WrappedComponent bindTracking={this.bindTracking}/>
      )
    }
  }
}

export default withAnalyticsWrapper;