class DaltonAPI {


    static NEWSLETTER_NAME = 'weeklycubenewsletter';

    static API_PROD_BASE_URL = 'https://cube.identityservices.io/core/api/1/';
    static API_QA_BASE_URL = 'https://cube.qa.identityservices.io/core/api/1/';
    
    static API_PROD_URL_NEWSLETTER = 'https://cube.identityservices.io/newsletters/api/1/subscriptions/add';
    static API_QA_URL_NEWSLETTER = 'https://cube.qa.identityservices.io/newsletters/api/1/subscriptions/add';
    
    static API_LOGIN = 'user/login';
    static API_LOGOUT = 'user/logout';
    static API_IDENTITY = 'identity';
    static API_USER = 'user';
    static API_RESET_PASSWORD = 'identity/password_reset_token';
    static API_RESET_PASSWORD_CODE = 'identity/password_reset_token?resetToken=';
    static API_CHANGE_PASSWORD = 'identity/password';
    static API_POST_ATTRIBUTES = 'user/attributes';

    static authToken = '';
    static isLoggedIn = false;

    static environment = '';

    static getBaseURL = () => {
        if (DaltonAPI.environment == 'production') {
            return DaltonAPI.API_PROD_BASE_URL;
        } else {
            return DaltonAPI.API_QA_BASE_URL;
        }
    }

    static getNewsletterURL = () => {
        if (DaltonAPI.environment == 'production') {
            return DaltonAPI.API_PROD_URL_NEWSLETTER;
        } else {
            return DaltonAPI.API_QA_URL_NEWSLETTER;
        }
    }

    static login = (eml, pw) => {
        var body = { 
            principal: eml,
            credential: pw,
            identityType: 'EMAIL'
        };
        
        var opts = DaltonAPI.getRequestOptions('POST', body);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_LOGIN, opts);
    }

    static logout = () => {
        var opts = DaltonAPI.getRequestOptions('POST', null, DaltonAPI.authToken);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_LOGOUT, opts);
    }

    static createIdentity = (eml, pw) => {
        var body = { 
            identityRequests: [
                {
                    principal: eml,
                    credential: pw,
                    identityType: 'EMAIL'
                }
            ]       
        };
        
        var opts = DaltonAPI.getRequestOptions('POST', body);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_IDENTITY, opts);
    }

    static createUser = (token, eml, initials, weekName) => {
        var body = { 
            emailAddress: eml,
            attributes: {
                'cube' : {
                    initials: initials
                }
            }
        };

        body.attributes.cube[`${weekName}_challengePlayCount`] = 0;
        body.attributes.cube[`${weekName}_entryCount`] = 0;

        var opts = DaltonAPI.getRequestOptions('POST', body, token);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_USER, opts);
    }

    static updateUserData = (dataStr, weekName) => {

        console.log('>> Dalton updateUserData');
        // console.log(dataStr);

        var userData = JSON.parse(dataStr);

        // console.log('>> parsed:');
        // console.log(userData);

        var body = {
            attributes: {
                'cube' : {}
            }
        };
        body.attributes.cube[`${weekName}_challengePlayCount`] = userData.challengePlayCount;
        body.attributes.cube[`${weekName}_entryCount`] = userData.entryCount;

        var opts = DaltonAPI.getRequestOptions('POST', body, DaltonAPI.authToken);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_POST_ATTRIBUTES, opts);
    }

    static getUser = (token) => {
        var opts = DaltonAPI.getRequestOptions('GET', null, token);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_USER, opts);
    }

    static addNewsletter = (eml) => {
        var body = {
            "emailAddress": eml,
            "newsletters": [DaltonAPI.NEWSLETTER_NAME]
        }
        var opts = DaltonAPI.getRequestOptions('POST', body);
        return DaltonAPI.goFetch(DaltonAPI.getNewsletterURL(), opts);
    }

    static resetPassword = (eml) => {
        var body = {
            principal: eml,
            identityType: 'EMAIL'
        };

        var opts = DaltonAPI.getRequestOptions('POST', body);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_RESET_PASSWORD, opts);
    }

    static resetPWCode = (code) => {
        var opts = DaltonAPI.getRequestOptions('GET');
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_RESET_PASSWORD_CODE + code, opts);
    }

    static changePassword = (pw) => { 

        var body = {
            credential: pw,
            identityType: 'EMAIL'
        }

        var opts = DaltonAPI.getRequestOptions('POST', body, DaltonAPI.authToken);
        return DaltonAPI.goFetch(DaltonAPI.getBaseURL() + DaltonAPI.API_CHANGE_PASSWORD, opts);
    }

    // utility methods: --------------------------------------

    // returns an object containing the options for fetch
    static getRequestOptions = (typ, bodyJSON, token) => {
        var obj = {
            method: typ,
            headers: token != null ? { 'Content-Type': 'application/json', 'Authorization': token } : { 'Content-Type': 'application/json' }
        }

        if (bodyJSON != null) {
           obj.body =  JSON.stringify(bodyJSON);
        }

        return obj;
    }

    // performs the fetch, returns the Promise of the parsing of the streamed output
    static goFetch = (url, options) => {
        return fetch(url, options).then(response => {
            if (response.ok) 
            {

                // console.log(response);
                
                var streamOutput = response.text().then(resp => {
                    return resp;
                });
                return streamOutput;

            } else {
                console.log('!! API error');
                var err = response.text().then(errorResponse => {
                    var errorObj = JSON.parse(errorResponse);
                    return errorObj;
                });
                return err;
            }
        })
        .catch(error => {
            console.log('>> fetch error');
            return false;
        });
    }


}
export default DaltonAPI;