import React, { Component } from 'react';
import withAnalyticsWrapper from '../AnalyticsWrapper/AnalyticsWrapper';
import { showSeriesNav, hidePageNav } from '../../utils/turnerUtils';
import Unity, { UnityContext } from "react-unity-webgl";
import Fullscreen from "../Fullscreen/Fullscreen";
import Loader from "../Loader/Loader";
import AccountTabs from "../AccountTabs/AccountTabs";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import LoginSuccess from "../LoginSuccess/LoginSuccess";
import LogoutSuccess from "../LogoutSuccess/LogoutSuccess";
import PWResetSuccess from "../PWResetSuccess/PWResetSuccess";
import ResetPWCode from "../ResetPWCode/ResetPWCode";
import NewPassword from "../NewPassword/NewPassword";
import DaltonAPI from "../../Services/DaltonAPI";
import Tracker from "../../utils/Tracker";
import SFX from "../../utils/SFX";
import tbsLogo from "../../assets/images/logo-tbs.svg";
import fbArrow from "../../assets/images/fbarrow.svg";
import UserData from "../../Models/UserData";
import './App.scss';

class App extends Component {

  constructor(props) {
    super(props);

    // strictly for use in setting the disclaimer text, not the game itself
    this.sweepsWeekNum = 100;
    this.weeks = [
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten"
    ];

    this.weekName = this.sweepsWeekNum == 100 ? "endless" : "week" + this.weeks[this.sweepsWeekNum];

    console.log('>> app weekName:', this.weekName);
    
    Tracker.bindTracking = this.props.bindTracking;

    this.UI_NONE                = 0;
    this.UI_ACCOUNT_SCREEN      = 1;
    this.UI_LOGIN               = 2;
    this.UI_FORGOT_PASSWORD     = 3;
    this.UI_LOGIN_SUCCESS       = 4;
    this.UI_PW_RESET_CODE       = 5;
    this.UI_NEW_PASSWORD        = 6;
    this.UI_LOGOUT_SUCCESS      = 7;
    this.UI_PW_RESET_SUCCESS    = 8;

    this.state = {
      isLoaded: false,
      progression: 0,
      isFullscreen: false,
      uiScreen: this.UI_NONE,
      isLoggedIn: false,
      authToken: ''
    };

    this.firstRotateEventFired = false;

    this.unityContext = new UnityContext({
      loaderUrl: process.env.PUBLIC_URL + "/game/cube.loader.js",
      dataUrl: process.env.PUBLIC_URL + "/game/cube.data",
      frameworkUrl: process.env.PUBLIC_URL + "/game/cube.framework.js",
      codeUrl: process.env.PUBLIC_URL + "/game/cube.wasm",
    });
    
    this.unityContext.on("debug", (message) => {
      console.log(message);
    });
    
    this.unityContext.on("error", (message) => {
      console.log("An error!", message);
    });

    this.unityContext.on("loaded", () => {
      this.setState({
        isLoaded: true,
      });
    });

    this.unityContext.on("progress", (progression) => {
      this.setState({
        progression: progression,
      });
    });

    this.unityContext.on("ShowAccountScreen", () => {
      this.showAccountScreen();
    });

    this.unityContext.on("ShowForgotPassword", () => {
      this.showForgotPasswordScreen();
    });

    this.unityContext.on("ShowResetPassword", () => {
      this.showResetPasswordScreen();
    });

    this.unityContext.on("ShowNewPassword", () => {
      this.showNewPasswordScreen();
    });

    this.unityContext.on("UpdateUserData", (data) => {
      DaltonAPI.updateUserData(data, this.weekName);
    });

    this.unityContext.on("ToggleFullscreen", () => {
      this.toggleFullscreen();
    });

    this.unityContext.on("Logout", () => {
      this.logout();
    });

    this.unityContext.on("TrackPageView", (data) => {
      var arr = data.split('|');
      Tracker.trackPageView(arr[0], arr[1]);
    });

    this.unityContext.on("TrackPageViewSponsor", (data) => {
      var arr = data.split('|');
      Tracker.trackPageViewSponsor(arr[0], arr[1], arr[2], arr[3]);
    });

    this.unityContext.on("TrackInteraction", (data) => {
      var arr = data.split('|');
      Tracker.trackInteraction(arr[0], arr[1]);
    });

    this.unityContext.on("TrackInteractionSponsor", (data) => {
      var arr = data.split('|');
      Tracker.trackInteractionSponsor(arr[0], arr[1], arr[2], arr[3]);
    });

    this.unityContext.on("TrackGameEvent", (data) => {
      var arr = data.split('|');
      Tracker.trackGameEvent(arr[0], arr[1], arr[2], arr[3], arr[4]);
    })

    this.unityContext.on("WebLink", (url) => {
      document.location.href = url;
    });

    this.unityContext.on("OpenURL", (url) => {
      window.open(url);
    });

    this.unityContext.on("GameManagerReady", () => {
      console.log('>> sending asset path... [ ' + process.env.PUBLIC_URL + ' ]');
      this.unityContext.send("GameManager", "SetAssetPath", process.env.PUBLIC_URL);
      this.unityContext.send("GameManager", "SetEnvironment", DaltonAPI.environment);

      this.initialRotateMessage();
      
    });

    this.unityContext.on("Encrypt", (payload) => {
      var benson = function benson(salt) {
        var textToChars = function textToChars(text) {
          return text.split('').map(function (c) {
            return c.charCodeAt(0);
          });
        };
        var byteHex = function byteHex(n) {
          return ("0" + Number(n).toString(16)).substr(-2);
        };
        var applySaltToChar = function applySaltToChar(code) {
          return textToChars(salt).reduce(function (a, b) {
            return a ^ b;
          }, code);
        };
        return function (text) {
          return text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
        };
      };
      var skips = benson('743d76be5863a99db32f897d84597b2a');
      this.unityContext.send("GameManager", "SendScore", skips(payload));
    });

  }

  componentDidMount() {

    this.checkForFacebook();

    // this doesn't work, set it manually below
    // DaltonAPI.environment = process.env.NODE_ENV;

    // set this to 'qa' or 'production'
    DaltonAPI.environment = 'production';

    var that = this;
    // turn off fullscreen if a resize is detected, which will happen if you press ESC to quit fullscreen
    // this.fireRotateMessage();
    window.addEventListener('fullscreenchange', this.fullscreenChangeHandler);
    window.addEventListener("orientationchange", function(event) {
        var scale = 'scale(1)';
        document.body.style.webkitTransform =  scale;    // Chrome, Opera, Safari
        document.body.style.msTransform =   scale;       // IE 9
        document.body.style.transform = scale;     // General
        
        if (event.target.innerWidth < event.target.innerHeight) {
          if (that.state.isFullscreen) {
            that.toggleFullscreen();
          }
        }

        that.fireRotateMessage();
    });

    // showSeriesNav();
    hidePageNav();

    if (document.location.search.indexOf('code=1') !== -1) {
      this.showPWResetCodeScreen();
    } else {
      Tracker.trackPageView('main menu', 'lander');
    }

    if (window.innerWidth < window.innerHeight) {
      document.body.classList.add('portrait');
    }


    // establish a method for toggling console logs
    var consoleHolder = console;
    window.debug = (bool) => {
      if(!bool){
          consoleHolder = console;
          console = {};
          Object.keys(consoleHolder).forEach(function(key){
              console[key] = function(){};
          })
      }else{
          console = consoleHolder;
      }
      return bool;
    }

    if (process.env.NODE_ENV === 'production') {
      window.debug(false);
    } else {
      window.debug(true);
    }

  }

  checkForFacebook = () => {
    if (
      window.navigator.userAgent.indexOf('FBIOS') != -1 ||
      window.navigator.userAgent.indexOf('FBAN') != -1 || 
      window.navigator.userAgent.indexOf('FBAV') != -1 ||
      window.navigator.userAgent.indexOf('Snapchat') != -1 ||
      window.navigator.userAgent.indexOf('Instagram') != -1)
    {
      this.showFBAppMessage();
    }
  }

  showAccountScreen = (e) => {
    this.setState({uiScreen: this.UI_ACCOUNT_SCREEN});
  }

  showForgotPasswordScreen = (e) => {
    this.setState({uiScreen: this.UI_FORGOT_PASSWORD});
    Tracker.trackPageView('login', 'forgot password - lander');
  }

  showPWResetCodeScreen = (e) => {
    this.setState({uiScreen: this.UI_PW_RESET_CODE});
    Tracker.trackPageView('login', 'password reset code - lander');
  }

  showNewPasswordScreen = (e) => {
    this.setState({uiScreen: this.UI_NEW_PASSWORD});
    Tracker.trackPageView('new password', 'lander');
  }

  updateUserData = (data) => {
    DaltonAPI.updateUserData(data, this.weekName)
    .then(profileData => {

      var profileJSON = JSON.parse(profileData);
      // console.log(profileJSON);

      if (profileJSON.errors && profileJSON.errors.length > 0) 
      {
          console.log('>> data update error');
          this.setState({
              hasError: true,
              errorMessage: 'Error updating user data'
          });
      } else {
          console.log('>> update user data SUCCESS');
      }
    });
  }

  logout = (e) => {
    DaltonAPI.logout().then(data => {
      console.log('>> logged out');

      this.setState(
        {
          uiScreen: this.UI_LOGOUT_SUCCESS,
          authToken: ''
        }
      );
      
      this.unityContext.send("GameManager", "ChangeAuth");

    });
  }

  render() {
    return (
      <div id="wrapper">
        <div className={this.state.isFullscreen ? 'cube-header hidden' : 'cube-header'}>
          <div className="tbs-logo">
            <a href="https://www.tbs.com"><img src={tbsLogo}/></a>
          </div>
          <div className="watch-the-cube">
            <a href="https://www.tbs.com/shows/the-cube">WATCH THE CUBE</a>
          </div>
        </div>
        <div className="game-wrapper">
          <Loader progression={this.state.progression} isLoaded={this.state.isLoaded}/>
          <Unity 
            className={this.state.isFullscreen ? 'game-canvas game-fullscreen' : 'game-canvas'} 
            unityContext={this.unityContext} 
            style={{
              visibility: this.state.isLoaded ? "visible" : "hidden"
            }}
          />

          <Fullscreen ready={this.state.isLoaded} handler={this.toggleFullscreen} isFullscreen={this.state.isFullscreen}/>
          <AccountTabs active={this.state.uiScreen === this.UI_ACCOUNT_SCREEN} 
            loginSuccessHandler={this.loginSuccessHandler} 
            createAccountSuccessHandler={this.createAccountSuccessHandler} 
            closeHandler={this.uiCloseHandler}
            forgotPasswordHandler={this.showForgotPasswordScreen} 
            bindTracking={this.bindTracking}
            weekName={this.weekName}
          />

          <ForgotPassword active={this.state.uiScreen === this.UI_FORGOT_PASSWORD}
            closeHandler={this.uiCloseHandler}
            backButtonHandler={this.showAccountScreen}
            showResetCodeInput = {this.showPWResetCodeScreen}
            successHandler={this.showNewPasswordScreen}/>

          <ResetPWCode active={this.state.uiScreen === this.UI_PW_RESET_CODE} 
            loggedIn="false"
            successHandler={this.showNewPasswordScreen}
            backButtonHandler={this.showForgotPasswordScreen} 
            closeHandler={this.uiCloseHandler}
            />

          <NewPassword active={this.state.uiScreen === this.UI_NEW_PASSWORD} 
            closeHandler={this.uiCloseHandler}
            pwChangeSuccessHandler={this.pwChangeSuccessHandler}
            />

          <LoginSuccess active={this.state.uiScreen === this.UI_LOGIN_SUCCESS} closeHandler={this.uiCloseHandler}/>

          <LogoutSuccess active={this.state.uiScreen === this.UI_LOGOUT_SUCCESS} closeHandler={this.uiCloseHandler}/>

          <PWResetSuccess active={this.state.uiScreen === this.UI_PW_RESET_SUCCESS} closeHandler={this.uiCloseHandler}/>
        </div>

        {/* <p className="legal">No purchase necessary. Void where prohibited. Open to residents of the 50 U.S. and D.C., 18 and older. Ends 11:59PM ET on {this.disclaimerDate()}. 
        See <a href="https://www.tbs.com/beat-the-cube/officialrules" target="_blank">Official Rules</a> for details.</p> */}

        <div className="fbpopupwrapper">
          <div className="fbpopup">
            <p><strong>In App Browsers are not Supported:</strong></p>
            <p>Tap the "..." menu<br/>and select<br/>"Open in Browser"<br/>to play.</p>            
          </div>
        </div>
      </div>
    );
  }

  toggleFullscreen = () => {
    // we are NOT currently fullscreen, so try to do so.
    // this only works for desktop.
    let elem = document.getElementById('wrapper');
    if (!this.state.isFullscreen) {
      if (!document.fullscreenElement) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen().catch(err => {
            // console.log('>> fullscreen error:' + err.message + ', ' + err.name);
          });
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        }
      }
      document.body.classList.add('game-fullscreen');
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen();
      }
      document.body.classList.remove('game-fullscreen');
    }

    this.setState(
      {
        isFullscreen: !this.state.isFullscreen
      }
    );
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

  initialRotateMessage = () => {

    if (!this.isTouchDevice()) return;


    console.log('-- initial rotate message');

    console.log('-- ' + window.innerWidth + window.innerHeight);

    var show = window.innerWidth < window.innerHeight;
    this.unityContext.send("GameManager", "DisplayRotateMessage", show ? "true" : "false");

    if (show) {
      document.body.classList.add('portrait');
    } else {
      document.body.classList.remove('portrait');
    }
  }

  fireRotateMessage = () => {
    
    if (!this.isTouchDevice()) return;
    var winNav = window.navigator;
    var isIOSChrome = winNav.userAgent.match("CriOS");
    var show = isIOSChrome ? window.innerWidth > window.innerHeight : window.innerWidth < window.innerHeight;
    this.unityContext.send("GameManager", "DisplayRotateMessage", show ? "true" : "false");

    if (show) {
      document.body.classList.remove('portrait');
    } else {
      document.body.classList.add('portrait');
    }

  }

  // this does not fire on mobile
  fullscreenChangeHandler = (evt) => {
    
    // console.log('>> fullscreen change');
    console.log(evt);

    var isFS = document.fullscreenElement != null;

    this.setState(
      {
        isFullscreen: document.fullscreenElement != null
      }
    );
    if (isFS) {
      document.body.classList.add('game-fullscreen');
    } else {
      document.body.classList.remove('game-fullscreen');
    }
  }

  createAccountSuccessHandler = (userData) => {
    this.setState(
      {
        uiScreen : this.UI_LOGIN_SUCCESS,
        authToken : userData.authToken
      }
    );

    Tracker.trackPageView('create account', 'success');

    this.unityContext.send("ProfileManager", "SetUserData", JSON.stringify(userData));
    this.unityContext.send("GameManager", "AuthMessage", 1);
  }

  loginSuccessHandler = (userData) => {
    this.setState(
      {
        uiScreen : this.UI_LOGIN_SUCCESS,
        authToken : userData.authToken
      }
    );

    Tracker.trackPageView('login', 'success');

    this.unityContext.send("ProfileManager", "SetUserData", JSON.stringify(userData));
    this.unityContext.send("GameManager", "AuthMessage", 1);
  }

  uiCloseHandler = () => {

    SFX.playClick();

    this.setState(
      {
        uiScreen : this.UI_NONE
      }
    );
  }

  showFBAppMessage = () => {
    document.body.classList.add('facebookapp');
  } 

  pwChangeSuccessHandler = () => {
    this.setState({
      uiScreen: this.UI_PW_RESET_SUCCESS
    });

    Tracker.trackPageView('new password', 'success');

    return DaltonAPI.authToken;
  }

  disclaimerDate() {
    var sweepsEndDates = 
    [
        "6/16/21",
        "6/23/21",
        "6/30/21",
        "7/7/21",
        "7/14/21",
        "7/21/21",
        "7/28/21",
        "8/4/21",
        "8/11/21",
        "8/26/21"
    ];

    return sweepsEndDates[this.sweepsWeekNum];
  }
}

export default withAnalyticsWrapper(App);