import React, { Component } from 'react';
import './CreateAccount.scss';
import InputBox from '../InputBox/InputBox';
import CheckBox from '../CheckBox/CheckBox';
import UserData from '../../Models/UserData';
import DaltonAPI from '../../Services/DaltonAPI';
import Tracker from "../../utils/Tracker";
import SFX from '../../utils/SFX';

class CreateAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            email: 'cube001@test.com',
            hasError: true,
            errorMessage: ' ',
            initials: '',
            hasAPIError: false,
            hasAcceptedTerms: false, //placeholder
            hasAcceptedNewsletter: false, //placeholder
            emailError: true,
            passwordError: true,
            initialsError: true
        };

        console.log('>> CreateAccount props', this.props.weekName);
    }

    static entryCount = 0;

    componentDidMount() {
    }

    render(props) {

        return <div className={this.props.active ? 'create-account active' : 'create-account'}>
            <div className="twoCol"> 
                <InputBox id="registerEmail" labelText="Email" linkedStateProp="email" onChange={this.handleInputBoxChange} onBlur={this.handleBlur} placeholder="email address" validate="email" 
                    errorText="Email: you must enter a valid email address"/>
                <InputBox id="registerPassword" labelText="Password" placeholder="password (8 or more characters)" isPassword="true" onChange={this.handleInputBoxChange} onBlur={this.handleBlur}
                    validate="createPassword" errorText="Password: must be 8 or more characters"/>
                <InputBox id="initials" labelText="Leaderboard Initials (3 letters)" linkedStateProp="initials" onChange={this.handleInputBoxChange} onBlur={this.handleBlur} placeholder="enter your initials" 
                    validate="initials" errorText="Leaderboard Initials: Inappropriate characters" maxLength="3"/>
            </div>
            <div className="twoCol">
                <CheckBox id="termsCheckbox" labelText="I accept all of the [Terms and Conditions|https://www.tbs.com/terms]" required="true" onChange={this.handleCheckBoxChange} linkedStateProp="hasAcceptedTerms"/>
                <CheckBox id="newsCheckbox" labelText="I would like to subscribe to the TBS newsletter ([Privacy Policy|https://www.warnermediaprivacy.com/policycenter/b2c/WME/en-us])" required="true" onChange={this.handleCheckBoxChange} linkedStateProp="hasAcceptedNewsletter"/>
                <p className='errorMessage'>{this.state.errorMessage}</p>
                <button id="submitCreateAccount" disabled={this.checkForErrors()} className="button submitButton" onClick={this.handleSubmit}>CREATE ACCOUNT</button>
            </div>
        </div>
    }

    checkForErrors = () => {
        return this.state.emailError || this.state.passwordError || this.state.initialsError || !this.state.hasAcceptedTerms;
    }

    handleBlur = (err) => {

        // errors labels are shown only on blur, so use this to fire analytics tag
        if (err) {
            if (!this.state.hasError) Tracker.trackPageView('create account', 'error');
        }

        this.setState({hasError: err});
    }

    handleInputBoxChange = (e) => {

        if (e != null) {

            var newState = {};

            if (e.linkedStateProp != null) {
                newState[e.linkedStateProp] = e.value;
            }

            if (e.error != null) {
                if (e.id === 'registerEmail') newState.emailError = e.error;
                if (e.id === 'registerPassword') newState.passwordError = e.error;
                if (e.id === 'initials') newState.initialsError = e.error;
            }

            this.setState(newState);
        }

    }

    handleCheckBoxChange = (e) => {

        // if we linked a state prop to a child InputBox
        if (e != null && e.linkedStateProp != null) {
            this.setState({
                [e.linkedStateProp]: e.checked
            });
        }
    }

    handleSubmit = (event) => {
        
        event.preventDefault();

        SFX.playClick();

        // validate the fields
        if (
            this.state.email === '' || 
            this.state.email.indexOf('@') === -1 || 
            this.state.email.indexOf('.') === -1 || 
            document.getElementById('registerPassword_inputField').value === ''
            )
        {
            this.setState({
                errorMessage: 'You must provide a valid email address and password to register.'
            });

            Tracker.trackPageView('create account', 'error');

            return;
        }

        if (!this.state.hasAcceptedTerms) {
            this.setState({
                errorMessage: 'You must accept the Terms and Conditions to register.'
            });

            Tracker.trackPageView('create account', 'error');
            
            return;
        }

        var eml = this.state.email;
        var initials = this.state.initials;
        var authToken = '';

        // make API calls to create account identity, then user
        DaltonAPI.createIdentity(eml, document.getElementById('registerPassword_inputField').value)
            .then(data => {
                if (data.errors && data.errors.length > 0) {
                    var msg = 'Could not create an account with this email address.';
                    switch (data.errors[0].error) 
                    {
                        case 'identity.already.in.use':
                            msg = 'An account already exists for this email. Please use another email address, or login.';
                            break;

                        default:
                            msg = 'There was an error. Please try again.';
                            break;
                    }

                    this.setState({
                        hasAPIError: true,
                        errorMessage: msg
                    });

                    Tracker.trackPageView('create account', 'error');

                    return false;

                } else {
                    // identity success, now set up user post

                    DaltonAPI.createUser(data, eml, initials, this.props.weekName)
                    .then(data => {
                        if (data.errors && data.errors.length > 0) {
                            this.setState({
                                hasAPIError: true,
                                errorMessage: data.errors[0].message
                            });

                            Tracker.trackPageView('create account', 'error');

                            return false;
                        } else {
                            // SUCCESS!
                            authToken = data;
                            this.setState({
                                hasAPIError: false,
                                errorMessage: ''
                            });

                            DaltonAPI.authToken = data;

                            var userData = new UserData();
                            userData.authToken = authToken;
                            userData.email = this.state.email;
                            userData.initials = this.state.initials;
                            userData.entryCount = CreateAccount.entryCount;
                            userData.userID = authToken.split('|')[0];
                            this.props.successHandler(userData);

                            // sign up for newsletter
                            if (this.state.hasAcceptedNewsletter)
                            {
                                DaltonAPI.addNewsletter(eml)
                                .then(data => {
                                    if (data.errors && data.errors.length > 0) {
                                        this.setState({
                                            hasAPIError: true,
                                            errorMessage: data.errors[0].message
                                        });
                                        return false;
                                    } else {
                                        // NEWSLETTER SUCCESS!

                                        console.log('>> NEWSLETTER SUCCESS')
                                        return true;
                                    }
                                });
                            }

                            return true;
                        }
                    });
                }
            }).catch(error => {
                console.log(error);
                return false;
            });
    }

}

export default CreateAccount;