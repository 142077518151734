class UserData
{
    constructor (token, id, eml, intls) {
        this.userID = id;
        this.authToken = token;
        this.email = eml;
        this.initials = intls;

        this.challengePlayCount = 0;
        this.entryCount = 0;
    }
    
}

export default UserData;