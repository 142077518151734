import React, { Component } from 'react';
import './Fullscreen.scss';

class Fullscreen extends Component {

    componentDidMount() {}

    render() {
        // if (this.props.ready) {
        //     return <div className={this.props.isFullscreen ? "game-fullscreen-button isFullscreen" : "game-fullscreen-button"} onClick={this.props.handler}>[&nbsp;&nbsp;]</div>;
        // } else {
        return null;
        // }
    }

}

export default Fullscreen;