import React, { Component } from 'react';
import './CheckBox.scss';

class CheckBox extends Component {

    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked,
            required: (this.props.required === 'true'),
            hasError: false
        }
    }

    componentDidMount() {}

    render() {
        return <div className="checkBox">
            <input className={this.state.hasError ? 'error' : ''} id={this.props.id + "_checkbox"} 
                type="checkbox" onChange={this.changeHandler}></input>
            <label className={this.state.hasError ? 'errorLabel' : ''} htmlFor={this.props.id + "_checkbox"} dangerouslySetInnerHTML={{__html: this.parseLinks(this.props.labelText)}}></label>
        </div>

    }

    parseLinks(txt) {
        while (txt.indexOf('[') != -1)
        {
            var start = txt.indexOf('[') + 1;
            var end = txt.indexOf(']');
            var chunk = txt.slice(start, end);
            var data = chunk.split('|');
            var label = data[0];
            var href = data[1];
            txt = txt.substring(0, start - 1) + '<a href="' + href + '" target="_blank">' + label + '</a>' + txt.substring(end + 1, txt.length + 1);
        }
        return txt;
    }

    changeHandler = (e) => {

        if (this.props.onChange) {

            // we will pass an event with a "value" prop on it to a onChange handler specified in the props
            // of this component, and set the parent state linked prop if specified

            var err = (this.state.required && !e.target.checked);
            var passedEvent = new Event('checkboxevent');
            passedEvent.checked = e.target.checked;
            passedEvent.error = err;
            passedEvent.linkedStateProp = this.props.linkedStateProp;
            this.setState({
                hasError: err,
                checked: e.target.checked
            });

            this.props.onChange(passedEvent);
        }
    }


}

export default CheckBox;